<template>
    <div>
        <el-main>
            <!-- 列表 -->
            <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }" :key="key">
                <template slot="empty">
                    <No />
                </template>
                <el-table-column prop="u_account" label="操作员" align="center" min-width="160"></el-table-column>
                <el-table-column prop="ip" label="IP地址" min-width="120" align="center"></el-table-column>
                <el-table-column prop="menu_name" label="操作模块" min-width="180" align="center"></el-table-column>
                <el-table-column prop="operation" label="操作动作" min-width="120" align="center"></el-table-column>
                <el-table-column label="操作时间" min-width="150" align="center">
                    <template v-slot="{ row }">{{ getDateformat(row.createtime) }}</template>
                </el-table-column>
                <!--<el-table-column prop="merchant_mobile" label="操作状态" width="110" align="center">
                    <template >成功 </template>
                </el-table-column>
                <el-table-column prop="jname" label="报错内容" width="120" align="center"></el-table-column>-->
            </el-table>
            <!-- 翻页 -->
            <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData">
            </Paging>
        </el-main>
    </div>
</template>
  
<script>

import _ from 'lodash';
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';

export default {
    components: {
        Paging,
    },
    data () {
        return {
            list: [],
            total_number: 0,
            searchForm: {
                page: 1,
                rows: 10,
            },
        };
    },
    created () {
        this.getList();
    },
    methods: {
        getDateformat (val) {
            return getDateformat(val);
        },
        updateData (val, status) {
            if (status == 0) {
                this.searchForm.rows = val;
                this.getList();
            } else {
                this.searchForm.page = val;
                this.getList();
            }
        },
        // 获取列表
        getList () {
            this.$axios.post(this.$api.set.AdminOperationLog, this.searchForm).then(res => {
                if (res.code == 0) {
                    this.list = res.result.list;
                    this.total_number = res.result.total_number;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;
}
</style>
  